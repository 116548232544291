/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Modal from 'react-modal';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import dayjs from './dayjs';
import useKitchenLayouts from './hooks/useKitchenLayouts';
import salesChannels from './SalesChannels';
import useAnycarryAddresses from './hooks/useAnycarryAddress';
import selfCheckoutModes from './SelfCheckoutModes';
import Utils from './Utils';

Modal.setAppElement('#root');

type ShopValues = {
  kitchen_shop_id: string | null;
  enabled: boolean;
  homepage_enabled: boolean;
  name: string;
  name_en: string;
  short_name: string;
  short_name_en: string;
  order: number;
  sales_channels: string[];
  address: string;
  address_en: string;
  email: string;
  kitchen_layout_id: string;
  query_place_id: string;
  anycarry_address_id: string;
  days_to_create_slots: number;
  closed_on_holidays: boolean;
  initial_order_sequence: number;
  max_order_sequence: number;
  batch_delivery: boolean;
  location_lat: number;
  location_long: number;
  parking_location_lat: number;
  parking_location_long: number;
  team_bonus_goal: number;
  team_bonus_coupon_discount_percentage: number;
  team_bonus_coupon_discount_amount: number;
  team_bonus_cashback_amount: number;
  team_bonus_group: string;
  friends_invitation_discount_percentage: number;
  shelf_layout: string;
  flash_message: string;
  flash_message_en: string;
  flash_message_until_date: Date;
  square_location_id: string;
  paylater_available: boolean;
  self_checkout_modes: string[];
  status: string;
  access: string;
  access_en: string;
  remark: string;
  remark_en: string;
  opening_days: string;
  opening_days_en: string;
  opening_hours: string;
  opening_hours_en: string;
  daily_slack_report_channel: string;
  bag_selectable: boolean;
  keywords: string;
  delivery_notes: string;
  batch_delivered_notification: string;
  batch_delivered_notification_en: string;
  batch_delivered_notification_zh: string;
  manual_url: string;
  delivery_instruction: string;
  shelf_sales_channels: string[];
  label_printer_sales_channels: string[];
  loading_stocks_timing: string;
  cooking_crew_level: number;
  skip_cooking_phase: boolean;
  holiday_start_date: string;
  holiday_end_date: string;

  time_discounts: {
    dates: string[];
    start_time: string;
    end_time: string;
    sales_channels: string[];
    discount_amount: number;
    order_app: boolean;
    self_checkout: boolean;
    qr_bento: boolean;
    self_checkout_audio_url: string;
  }[];

  auto_qr_bento_orders: {
    start_time: string;
    end_time: string;
    threshold: number;
    add_amount: number;
  }[];

  self_checkout_standby_audios: {
    start_time: string;
    end_time: string;
    audio_url: string;
  }[];
};

function Shop(props: { match }) {
  const history = useHistory();
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm<ShopValues>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'time_discounts',
  });

  const {
    fields: autoOrderFields,
    append: appendAutoOrder,
    remove: removeAutoOrder,
  } = useFieldArray({
    control,
    name: 'auto_qr_bento_orders',
  });

  const {
    fields: selfCheckoutStandbyAudiosFields,
    append: appendSelfCheckoutStandbyAudios,
    remove: removeSelfCheckoutStandbyAudios,
  } = useFieldArray({
    control,
    name: 'self_checkout_standby_audios',
  });

  const [posting, setPosting] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  const [messageError, setMessageError] = useState('');
  const [shop, setShop] = useState<any>();
  const [shops, setShops] = useState<any>();
  const [id, setId] = useState<string>();
  const [isExistingShop, setIsExistingShop] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [imageUrls, setImageUrls] = useState<Array<string>>([]);
  const [shopDisabled, setShopDisabled] = useState(false);
  const kitchenLayoutSnapshots = useKitchenLayouts();

  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.75)',
    },
    content: {
      position: 'absolute',
      top: '0px',
      left: '0px',
      right: '0px',
      bottom: '0px',
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: '0px',
    },
  };

  useEffect(() => {
    const unregisterShopsObserver = firebase
      .firestore()
      .collection('shops')
      .orderBy('order')
      .onSnapshot((snap) => {
        console.log('onSnapshot Shops');
        const records = {};
        for (const docSnapshot of snap.docs) {
          const data = docSnapshot.data();
          records[docSnapshot.id] = data;
        }
        setShops(records);
      });

    return () => {
      unregisterShopsObserver();
    };
  }, []);

  useEffect(() => {
    let givenId: string;

    if (props.match.params.id === 'NEW') {
      setShop({});
      setValue('closed_on_holidays', true);
      setImageUrls(['https://tokyomixcurry.com/images/shop-noimage.jpg']);
      givenId = firebase.firestore().collection('shops').doc().id;
    } else {
      givenId = props.match.params.id;
    }
    setId(givenId);

    const unregisterShopObserver = firebase
      .firestore()
      .collection('shops')
      .doc(givenId)
      .onSnapshot((doc) => {
        console.log('onSnapshot Shop');
        if (doc.exists) {
          setShop(doc.data());
          reset(doc.data());
          setShopDisabled(doc.data()!.status !== 'active');
          setImageUrls(doc.data()!.image_urls || []);
          setIsExistingShop(true);
        } else {
          reset({ status: 'active', enabled: true, homepage_enabled: true });
        }
      });

    return () => {
      unregisterShopObserver();
    };
  }, [props.match.params.id, reset, setValue]);

  const emptyDiv = () => <div />;
  const onSubmit = (data) => {
    const options = {
      title: '店舗情報の更新を行います',
      message: '変更は直ちに反映されます',
      buttons: [
        {
          label: '更新する',
          onClick: () => {
            submit(data);
          },
        },
        {
          label: 'キャンセルする',
          onClick: () => {},
        },
      ],
      childrenElement: () => emptyDiv(),
      closeOnEscape: true,
      closeOnClickOutside: true,
      willUnmount: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
    };

    confirmAlert(options);
  };

  const afterOpenModal = () => {};

  const closeModal = () => {};

  const submit = (argData) => {
    setPosting(true);
    const data = { ...argData };

    data.order = parseInt(data.order, 10);

    if (data.kitchen_shop_id === '') {
      data.kitchen_shop_id = null;
    }

    if (data.kitchen_layout_id === '') {
      data.kitchen_layout_id = null;
    }

    if (data.query_place_id === '') {
      data.query_place_id = null;
    }

    if (data.anycarry_address_id === '') {
      data.anycarry_address_id = null;
    }

    if (data.days_to_create_slots) {
      data.days_to_create_slots = parseInt(data.days_to_create_slots, 10);
    }

    if (!data.holiday_start_date) {
      data.holiday_start_date = null;
    }
    if (!data.holiday_end_date) {
      data.holiday_end_date = null;
    }

    data.initial_order_sequence = parseInt(data.initial_order_sequence, 10);
    data.max_order_sequence = parseInt(data.max_order_sequence, 10);

    if (data.location_lat && data.location_long) {
      data.location = new firebase.firestore.GeoPoint(parseFloat(data.location_lat), parseFloat(data.location_long));
    } else {
      data.location = null;
    }

    if (data.parking_location_lat && data.parking_location_long) {
      data.parking_location = new firebase.firestore.GeoPoint(
        parseFloat(data.parking_location_lat),
        parseFloat(data.parking_location_long),
      );
    } else {
      data.parking_location = null;
    }

    if (data.team_bonus_goal) {
      data.team_bonus_goal = parseInt(data.team_bonus_goal, 10);
    } else {
      data.team_bonus_goal = null;
    }
    if (data.team_bonus_coupon_discount_percentage) {
      data.team_bonus_coupon_discount_percentage = parseInt(data.team_bonus_coupon_discount_percentage, 10);
    } else {
      data.team_bonus_coupon_discount_percentage = null;
    }

    if (data.team_bonus_coupon_discount_amount) {
      data.team_bonus_coupon_discount_amount = parseInt(data.team_bonus_coupon_discount_amount, 10);
    } else {
      data.team_bonus_coupon_discount_amount = null;
    }
    if (data.team_bonus_cashback_amount) {
      data.team_bonus_cashback_amount = parseInt(data.team_bonus_cashback_amount, 10);
    } else {
      data.team_bonus_cashback_amount = null;
    }
    if (data.team_bonus_group) {
      data.team_bonus_group = data.team_bonus_group.trim();
    } else {
      data.team_bonus_group = null;
    }

    if (data.friends_invitation_discount_percentage) {
      data.friends_invitation_discount_percentage = parseInt(data.friends_invitation_discount_percentage, 10);
    } else {
      data.friends_invitation_discount_percentage = null;
    }

    if (!data.shelf_layout) {
      data.shelf_layout = null;
    }

    delete data.location_lat;
    delete data.location_long;
    delete data.parking_location_lat;
    delete data.parking_location_long;

    if (data.flash_message_until_date) {
      data.flash_message_until = dayjs.tz(data.flash_message_until_date, 'Asia/Tokyo').toDate();
    } else {
      data.flash_message_until = null;
    }

    delete data.flash_message_until_date;

    data.image_urls = imageUrls;

    if (!data.square_location_id) {
      data.square_location_id = null;
      data.self_checkout_modes = null;
      data.paylater_available = false;
    }

    if (data.self_checkout_modes) {
      if (data.self_checkout_modes.length === 0) {
        data.self_checkout_modes = null;
        data.paylater_available = false;
      } else {
        // アプリの最低バージョンが1.1.119になったら削除する
        data.paylater_available = data.self_checkout_modes.includes('paylater');
      }
    }

    const level = Number.parseInt(data.cooking_crew_level, 10);
    data.cooking_crew_level = Utils.isNumber(level) ? level : null;

    if (data.status === 'inactive') {
      data.enabled = false;
      data.homepage_enabled = false;
    }

    if (data.time_discounts && data.time_discounts.length > 0) {
      data.time_discounts = data.time_discounts.map((timeDiscount) => {
        return {
          ...timeDiscount,
          discount_amount: parseInt(timeDiscount.discount_amount, 10),
          order_app: !!timeDiscount.order_app,
          self_checkout: !!timeDiscount.self_checkout,
        };
      });
    }

    const shopRef = firebase.firestore().collection('shops').doc(id);
    shopRef
      .set(data, { merge: true })
      .then(() => {
        setMessageSuccess('更新完了');
        history.push(`/admin/shops/${shopRef.id}`);
        setPosting(false);
      })
      .catch((error) => {
        setMessageError(`更新に失敗しました${error}`);
        setPosting(false);
      });
  };

  const handleUpload = (e) => {
    e.preventDefault();

    const file = e.target.files[0];

    let fileName;
    if (file.type === 'image/png') {
      fileName = `shop_${new Date().getTime()}.png`;
    } else if (file.type === 'image/jpeg') {
      fileName = `shop_${new Date().getTime()}.jpg`;
    } else {
      throw new Error("shouldn't come here");
    }

    const path = `/shops/${id}`;
    const fullPath = `${path}/${fileName}`;
    const uploadTask = firebase.storage().ref(fullPath).put(file);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // progress function ...
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setUploadProgress(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          setImageUrls((prevState) => {
            const newValue = [...prevState, downloadURL];
            return newValue;
          });
        });
      },
    );
  };

  const progressMessage = () => {
    let msg: string;
    if (uploadProgress === 100) {
      msg = 'アップロード完了';
    } else if (uploadProgress > 0) {
      msg = `${uploadProgress}%`;
    } else {
      msg = '';
    }
    return msg;
  };

  const deleteImage = (deletingUrl: string) => {
    setImageUrls((prevState) => {
      const newValue = [...prevState].filter((url) => url !== deletingUrl);
      return newValue;
    });
  };

  const watchKitchenShopId = watch('kitchen_shop_id', shop?.kitchen_shop_id ?? '');
  const anycarryAddresses = useAnycarryAddresses();

  const statusChanged = (e) => {
    if (e.target.value === 'active') {
      setShopDisabled(false);
      setValue('enabled', !!shop.enabled);
      setValue('homepage_enabled', !!shop.homepage_enabled);
    } else {
      setShopDisabled(true);
    }
  };

  return (
    <div className="container-fluid h-100">
      {shop && shops && kitchenLayoutSnapshots && anycarryAddresses ? (
        <div>
          {messageSuccess ? <div className="alert alert-success">{messageSuccess}</div> : ''}

          {messageError ? <div className="alert alert-danger">{messageError}</div> : ''}

          <>
            {shop ? (
              <div>
                <form id="shop-form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">名称（日本語)</label>
                    <div className="col-sm-8">
                      <input type="text" size={50} {...register('name', { required: true })} />
                      <small className="form-text text-muted">アプリに表示されます</small>
                      <small className="text-danger">{errors.name && '必須です'}</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">名称（英語）</label>
                    <div className="col-sm-8">
                      <input type="text" size={50} {...register('name_en', {})} />
                      <small className="form-text text-muted">アプリに表示されます</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">短縮名（日本語）</label>
                    <div className="col-sm-8">
                      <input type="text" size={50} {...register('short_name', { required: true })} />
                      <small className="form-text text-muted">アプリに表示されます</small>
                      <small className="text-danger">{errors.short_name && '必須です'}</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">短縮名（英語）</label>
                    <div className="col-sm-8">
                      <input type="text" size={50} {...register('short_name_en', {})} />
                      <small className="form-text text-muted">アプリに表示されます</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">アプリ等での表示順</label>
                    <div className="col-sm-8">
                      <input type="number" {...register('order', { required: true })} />
                      <small className="text-danger">{errors.order && '必須です'}</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">オーダーアプリ販売チャネル</label>
                    <div className="col-sm-8">
                      <select
                        multiple
                        size={6}
                        {...register('sales_channels', { required: true })}
                        onChange={(event) => {
                          const selectedValues = Array.from(event.target.selectedOptions, (option) => option.value);
                          const selectedSalesChannels = selectedValues.map(
                            (value) => salesChannels.find((salesChannel) => salesChannel.value === value)?.value,
                          );

                          setValue(
                            'batch_delivery',
                            selectedSalesChannels.includes('office_delivery') ||
                              selectedSalesChannels.includes('stand'),
                          );
                        }}
                      >
                        {salesChannels
                          .filter((salesChannel) => salesChannel.orderApp)
                          .map((salesChannel) => (
                            <option key={salesChannel.value} value={salesChannel.value}>
                              {salesChannel.name}
                            </option>
                          ))}
                      </select>
                      <small className="text-danger">{errors.sales_channels && '必須です'}</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">店舗画像</label>

                    <div className="col-sm-8">
                      {imageUrls &&
                        imageUrls.map((url, i) => (
                          <>
                            <img alt={`shop${i}`} src={url} style={{ width: '240px' }} />
                            <button
                              type="button"
                              onClick={() => {
                                deleteImage(url);
                              }}
                            >
                              削除
                            </button>
                          </>
                        ))}

                      <div>
                        <label htmlFor="shopImageUpload">
                          追加
                          <input
                            id="shopImageUpload"
                            type="file"
                            name="file"
                            className="form-control-file"
                            accept="image/jpeg, image/png"
                            onChange={handleUpload}
                          />
                        </label>
                        {progressMessage()}
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">盛り付けを行う店舗</label>
                    <div className="col-sm-8">
                      <select
                        {...register('kitchen_shop_id', {
                          validate: async (value: string | null) => {
                            if (id && value) {
                              const childShops = await firebase
                                .firestore()
                                .collection('shops')
                                .where('kitchen_shop_id', '==', id)
                                .get();
                              if (childShops.size > 0) {
                                return `${childShops.docs
                                  .map((doc) => doc.data().short_name)
                                  .join(', ')} の親店舗として設定されているため変更できません。`;
                              }
                            }
                            return true;
                          },
                        })}
                      >
                        <option key="none" value="">
                          -
                        </option>
                        {Object.keys(shops).map((shopId) => (
                          <option key={shopId} value={shopId}>
                            {shops[shopId].short_name}
                          </option>
                        ))}
                      </select>
                      <small className="form-text text-muted">
                        1日の食数上限やトッピングの在庫管理も指定した店舗で行われます。
                      </small>
                      <small className="text-danger">
                        {errors.kitchen_shop_id && (errors.kitchen_shop_id.message as string)}
                      </small>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">盛り付けレイアウト</label>
                    <div className="col-sm-8">
                      <select
                        {...register('kitchen_layout_id', {
                          validate: watchKitchenShopId === '' ? (value) => value !== '' : undefined,
                        })}
                      >
                        <option key="none" value="">
                          -
                        </option>
                        {kitchenLayoutSnapshots?.map((kitchenLayoutSnapshot) => (
                          <option key={kitchenLayoutSnapshot.id} value={kitchenLayoutSnapshot.id}>
                            {kitchenLayoutSnapshot.data().name}
                          </option>
                        ))}
                      </select>
                      <small className="form-text text-muted">
                        盛り付けを行う店舗を設定した場合は盛り付けレイアウトの設定は不要です。
                      </small>
                      <small className="text-danger">{errors.kitchen_layout_id && '親店舗の場合は必須です'}</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">問い合わせメールアドレス</label>
                    <div className="col-sm-8">
                      <input type="text" size={50} {...register('email', { required: true })} />
                      <small className="form-text text-muted">
                        アプリからのお問い合わせで使用されるアドレス。
                        info+&lt;店舗を識別できる英数字&gt;@tokyomixcurry.com を指定する。
                      </small>

                      <small className="text-danger">{errors.email && '必須です'}</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">住所（日本語）</label>
                    <div className="col-sm-8">
                      <input type="text" size={80} {...register('address', { required: true })} />
                      <small className="text-danger">{errors.address && '必須です'}</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">住所（英語）</label>
                    <div className="col-sm-8">
                      <input type="text" size={80} {...register('address_en', {})} />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">長期休暇開始日</label>
                    <div className="col-sm-8">
                      <Controller
                        control={control}
                        {...register('holiday_start_date', {})}
                        render={({ field: { onChange, name, value } }) => (
                          <>
                            <DatePicker
                              plugins={[<DatePanel />]}
                              value={value || null}
                              onChange={(date: DateObject) => {
                                if (date) {
                                  onChange(date.format('YYYY-MM-DD'));
                                } else {
                                  onChange('');
                                }
                              }}
                              format="YYYY-MM-DD"
                            />
                          </>
                        )}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">長期休暇終了日</label>
                    <div className="col-sm-8">
                      <Controller
                        control={control}
                        {...register('holiday_end_date', {})}
                        rules={{}}
                        render={({ field: { onChange, name, value } }) => (
                          <>
                            <DatePicker
                              plugins={[<DatePanel />]}
                              value={value || null}
                              onChange={(date: DateObject) => {
                                if (date) {
                                  onChange(date.format('YYYY-MM-DD'));
                                } else {
                                  onChange('');
                                }
                              }}
                              format="YYYY-MM-DD"
                            />
                          </>
                        )}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">配達元エニキャリ/Wolt/TIMEBOX住所</label>

                    {shop.kitchen_shop_id ? (
                      <div className="col-sm-8">
                        <p className="text-warning">
                          配達先のエニキャリ/Wolt/TIMEBOX住所はスロットパターンで指定してください
                        </p>
                      </div>
                    ) : (
                      <div className="col-sm-8">
                        <select className="form-control form-control-sm" {...register('anycarry_address_id')}>
                          <option value="">-</option>
                          {anycarryAddresses.map((anycarryAddress) => (
                            <option key={anycarryAddress.id} value={anycarryAddress.id}>
                              {anycarryAddress.data().name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">店舗緯度</label>
                    <div className="col-sm-8">
                      <input
                        defaultValue={shop.location ? shop.location.latitude : null}
                        type="text"
                        size={50}
                        {...register('location_lat', { required: true })}
                      />
                      <small className="text-danger">{errors.location_lat && '必須です'}</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">店舗経度</label>
                    <div className="col-sm-8">
                      <input
                        defaultValue={shop.location ? shop.location.longitude : null}
                        type="text"
                        size={50}
                        {...register('location_long', { required: true })}
                      />
                      <small className="text-danger">{errors.location_long && '必須です'}</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">駐輪場緯度</label>
                    <div className="col-sm-8">
                      <input
                        defaultValue={shop.parking_location ? shop.parking_location.latitude : null}
                        type="text"
                        size={50}
                        {...register('parking_location_lat', {})}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">駐輪場経度</label>
                    <div className="col-sm-8">
                      <input
                        defaultValue={shop.parking_location ? shop.parking_location.longitude : null}
                        type="text"
                        size={50}
                        {...register('parking_location_long', {})}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">アクセス（日本語）</label>
                    <div className="col-sm-8">
                      <input type="text" size={80} {...register('access', { required: true })} />
                      <small className="text-danger">{errors.access && '必須です'}</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">アクセス（英語）</label>
                    <div className="col-sm-8">
                      <input type="text" size={80} {...register('access_en', {})} />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">店舗からの注意事項（日本語）</label>
                    <div className="col-sm-8">
                      <input type="text" size={80} {...register('remark', {})} />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">店舗からの注意事項（英語）</label>
                    <div className="col-sm-8">
                      <input type="text" size={80} {...register('remark_en', {})} />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">営業日（日本語）</label>
                    <div className="col-sm-8">
                      <input type="text" size={80} {...register('opening_days', { required: true })} />
                      <small className="text-danger">{errors.opening_days && '必須です'}</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">営業日（英語）</label>
                    <div className="col-sm-8">
                      <input type="text" size={80} {...register('opening_days_en', {})} />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">営業時間（日本語）</label>
                    <div className="col-sm-8">
                      <input type="text" size={80} {...register('opening_hours', { required: true })} />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">営業時間（英語）</label>
                    <div className="col-sm-8">
                      <input type="text" size={80} {...register('opening_hours_en', {})} />
                      <small className="text-danger">{errors.opening_hours && '必須です'}</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">検索キーワード</label>
                    <div className="col-sm-8">
                      <input type="text" size={80} {...register('keywords', {})} />
                      <small className="form-text text-muted">
                        アプリの店舗検索で使用されます。,区切りで複数キーワード記述できます。 (現在、オフィス検索のみ)
                      </small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">店舗からのお知らせ（日本語）</label>
                    <div className="col-sm-8">
                      <input type="text" size={80} {...register('flash_message', {})} />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">店舗からのお知らせ（英語）</label>
                    <div className="col-sm-8">
                      <input type="text" size={80} {...register('flash_message_en', {})} />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">店舗からのお知らせ表示期限</label>
                    <div className="col-sm-8">
                      <input
                        type="datetime-local"
                        defaultValue={
                          shop.flash_message_until
                            ? dayjs(shop.flash_message_until.toDate()).tz('Asia/Tokyo').format('YYYY-MM-DDTHH:mm')
                            : ''
                        }
                        {...register('flash_message_until_date', {})}
                      />
                      まで表示
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">スロット作成日数(営業日)</label>

                    {shop.kitchen_shop_id ? (
                      <div className="col-sm-8">
                        <p className="text-warning">盛り付けを行う店舗で設定してください</p>
                      </div>
                    ) : (
                      <div className="col-sm-8">
                        <input
                          type="number"
                          size={20}
                          {...register('days_to_create_slots', { required: true, pattern: /\d+/ })}
                        />
                        <small className="form-text text-muted">
                          ここで指定した営業日数だけスロットを事前作成します。スロットは深夜2時に作成されます。
                        </small>
                        <small className="text-danger">{errors.days_to_create_slots && '必須です'}</small>
                      </div>
                    )}
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">休日閉店</label>

                    {shop.kitchen_shop_id ? (
                      <div className="col-sm-8">
                        <p className="text-warning">盛り付けを行う店舗で設定してください</p>
                      </div>
                    ) : (
                      <div className="col-sm-8">
                        <input type="checkbox" size={20} {...register('closed_on_holidays', {})} />
                        <small className="form-text text-muted">
                          スロットパターンの自動設定時に休日(土日祝日)は設定しない
                        </small>
                      </div>
                    )}
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">注文番号初期値</label>
                    <div className="col-sm-8">
                      <input
                        type="number"
                        size={20}
                        {...register('initial_order_sequence', { required: true, pattern: /\d+/ })}
                      />
                      <small className="form-text text-muted">注文番号の初期値</small>
                      <small className="text-danger">{errors.initial_order_sequence && '必須です'}</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">注文番号最大値</label>
                    <div className="col-sm-8">
                      <input
                        type="number"
                        size={20}
                        {...register('max_order_sequence', { required: true, pattern: /\d+/ })}
                      />
                      <small className="form-text text-muted">注文番号の最大値(最大値を超えると初期値に戻る)</small>
                      <small className="text-danger">{errors.max_order_sequence && '必須です'}</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">Slack Channel ID</label>
                    <div className="col-sm-8">
                      <input type="text" name="slack_channel_id" size={80} readOnly />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">領収書店舗コード</label>
                    <div className="col-sm-8">
                      <input type="text" name="receipt_prefix" size={10} readOnly />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">Google Map Place ID</label>
                    <div className="col-sm-8">
                      <input type="text" size={80} {...register('query_place_id', {})} />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">日次Slackレポートチャンネル</label>
                    <div className="col-sm-8">
                      <input type="text" size={80} {...register('daily_slack_report_channel', {})} />
                      <small className="form-text text-muted">毎日21時にこのチャンネルにレポートされます</small>
                    </div>
                  </div>

                  <hr />

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">有料レジ袋オプション表示</label>
                    <div className="col-sm-8">
                      <input type="checkbox" size={20} {...register('bag_selectable', {})} />
                      <small className="form-text text-muted">
                        これを指定するとアプリで、ユーザーがレジ袋(+3円)を選択することができます。デリバリー店では通常指定しません。
                      </small>
                    </div>
                  </div>

                  <hr />

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">親店舗デリバリー注意事項(親店舗のみ)</label>
                    <div className="col-sm-8">
                      <textarea cols={80} rows={5} {...register('delivery_notes', {})} />
                      <small className="form-text text-muted">親店舗毎にデリバリーアプリ上部に表示される</small>
                    </div>
                  </div>

                  <hr />

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">法人(まとめ)デリバリー対象店舗</label>
                    <div className="col-sm-8">
                      <input defaultChecked={shop.batch_delivery} type="checkbox" size={20} disabled />
                      <small className="form-text text-muted">法人(まとめ)デリバリーを行う場合に指定する</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">
                      法人デリバリー/スタンドでデリバリー完了時の通知メッセージ
                    </label>
                    <div className="col-sm-8">
                      <input type="text" size={80} {...register('batch_delivered_notification', {})} />
                      <small className="form-text text-muted">
                        「～番の注文が到着しました。」と合わせて通知されます。
                      </small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">
                      法人デリバリー/スタンドでデリバリー完了時の通知メッセージ(英語)
                    </label>
                    <div className="col-sm-8">
                      <input type="text" size={80} {...register('batch_delivered_notification_en', {})} />
                      <small className="form-text text-muted">「No. 番号 has arrived」と合わせて通知されます。</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">
                      法人デリバリー/スタンドでデリバリー完了時の通知メッセージ(中国語)
                    </label>
                    <div className="col-sm-8">
                      <input type="text" size={80} {...register('batch_delivered_notification_zh', {})} />
                      <small className="form-text text-muted">「～号订单已到达。」と合わせて通知されます。</small>
                    </div>
                  </div>

                  <hr />

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">店舗/配達マニュアルURL</label>
                    <div className="col-sm-8">
                      <input type="text" size={80} {...register('manual_url', {})} />
                      <small className="form-text text-muted" />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">配達注意事項</label>
                    <div className="col-sm-8">
                      <textarea cols={80} rows={5} {...register('delivery_instruction', {})} />
                      <small className="form-text text-muted">デリバリーアプリに表示(オフィス配達のみ)</small>
                    </div>
                  </div>

                  <hr />

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">チームボーナスゴール食数</label>
                    <div className="col-sm-8">
                      <input type="number" size={80} {...register('team_bonus_goal', {})} />
                      <small className="form-text text-muted" />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">チームボーナスクーポン割引率</label>
                    <div className="col-sm-8">
                      <input type="number" size={80} {...register('team_bonus_coupon_discount_percentage', {})} />
                      <small className="form-text text-muted">
                        クーポン割引率/額またはキャッシュバック額の中でどれか一つ
                      </small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">チームボーナスクーポン割引額</label>
                    <div className="col-sm-8">
                      <input type="number" size={80} {...register('team_bonus_coupon_discount_amount', {})} />
                      <small className="form-text text-muted">
                        クーポン割引率/額またはキャッシュバック額の中でどれか一つ
                      </small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">チームボーナスキャッシュバック額</label>
                    <div className="col-sm-8">
                      <input type="number" size={80} {...register('team_bonus_cashback_amount', {})} />
                      <small className="form-text text-muted">
                        クーポン割引率/額またはキャッシュバック額の中でどれか一つ
                      </small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">チームボーナスグループ</label>
                    <div className="col-sm-8">
                      <input type="string" size={20} {...register('team_bonus_group', {})} />
                      <small className="form-text text-muted">
                        同じチームボーナスグループが設定された店舗間でチームボーナス達成に向け注文数が合算されます。
                      </small>
                    </div>
                  </div>

                  <hr />

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">友達紹介割引率</label>
                    <div className="col-sm-8">
                      <input type="number" size={80} {...register('friends_invitation_discount_percentage', {})} />
                      <small className="form-text text-muted">
                        デフォルト値から変更する場合のみ設定 /
                        設定した場合この店舗と、チームボーナスグループが同一の店舗でしか使用できなくなります。
                      </small>
                    </div>
                  </div>

                  <hr />
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">ピックアップカウンターの配置</label>

                    {shop.kitchen_shop_id ? (
                      <div className="col-sm-8">
                        <p className="text-warning">親店舗のみ</p>
                      </div>
                    ) : (
                      <div className="col-sm-8">
                        <textarea {...register('shelf_layout', {})} />
                        <small className="form-text text-muted">
                          ピックアップカウンターの配置を指定します。,区切り
                        </small>
                      </div>
                    )}
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">ピックアップカウンターを使用する販売チャネル</label>

                    <div className="col-sm-8">
                      <div className="col-sm-8">
                        <select multiple size={6} {...register('shelf_sales_channels', {})}>
                          {salesChannels.map((salesChannel) => (
                            <option key={salesChannel.value} value={salesChannel.value}>
                              {salesChannel.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">オフィスデリバリーWebオーダーアプリURL</label>
                    <div className="col-sm-8">
                      {shop.sales_channels?.includes('office_delivery')
                        ? `${process.env.REACT_APP_web_order_app}/?sid=${id}&sc=office_delivery`
                        : '-'}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">QR弁当購入URL</label>
                    <div className="col-sm-8">{`${process.env.REACT_APP_qr_bento_payment}/?sid=${id}`}</div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">QR弁当自動追加設定</label>

                    {shop.kitchen_shop_id ? (
                      <div className="col-sm-8">
                        <p className="text-warning">親店舗のみ</p>
                      </div>
                    ) : (
                      <div className="col-sm-12">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() =>
                            appendAutoOrder({
                              start_time: '',
                              end_time: '',
                              threshold: 0,
                              add_amount: 1,
                            })
                          }
                        >
                          追加
                        </button>

                        {autoOrderFields.map((field, index) => (
                          <div key={field.id} className="row ml-5 mb-3">
                            <div className="col">
                              <label className="form-label">開始時間</label>
                              <input
                                className="form-control"
                                {...register(`auto_qr_bento_orders.${index}.start_time`, { required: true })}
                                type="time"
                              />
                            </div>

                            <div className="col">
                              <label className="form-label">終了時間</label>
                              <input
                                className="form-control"
                                {...register(`auto_qr_bento_orders.${index}.end_time`, { required: true })}
                                type="time"
                              />
                            </div>

                            <div className="col">
                              <label className="form-label">残り数(閾値)</label>
                              <input
                                className="form-control"
                                {...register(`auto_qr_bento_orders.${index}.threshold`, {
                                  required: true,
                                  min: 0,
                                  valueAsNumber: true,
                                })}
                                type="number"
                              />
                            </div>

                            <div className="col">
                              <label className="form-label">追加数</label>
                              <input
                                className="form-control"
                                {...register(`auto_qr_bento_orders.${index}.add_amount`, {
                                  required: true,
                                  min: 1,
                                  valueAsNumber: true,
                                })}
                                type="number"
                              />
                            </div>

                            <div className="col">
                              <button type="button" className="btn btn-danger" onClick={() => removeAutoOrder(index)}>
                                削除
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <hr />

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">Square Location ID</label>
                    <div className="col-sm-8">
                      <input type="text" size={80} {...register('square_location_id', {})} />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">セルフレジモード</label>
                    <div className="col-sm-8">
                      <select multiple size={6} {...register('self_checkout_modes', {})}>
                        {selfCheckoutModes.map((selfCheckoutMode) => (
                          <option key={selfCheckoutMode.value} value={selfCheckoutMode.value}>
                            {selfCheckoutMode.name}
                          </option>
                        ))}
                      </select>
                      <small className="text-danger">{errors.sales_channels && '必須です'}</small>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">決済レジ待機音声</label>

                    {shop.kitchen_shop_id ? (
                      <div className="col-sm-8">
                        <p className="text-warning">親店舗のみ</p>
                      </div>
                    ) : (
                      <div className="col-sm-12">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() =>
                            appendSelfCheckoutStandbyAudios({
                              start_time: '',
                              end_time: '',
                              audio_url: '',
                            })
                          }
                        >
                          追加
                        </button>

                        {selfCheckoutStandbyAudiosFields.map((field, index) => (
                          <div key={field.id} className="row ml-5 mb-3">
                            <div className="col">
                              <label className="form-label">開始時間</label>
                              <input
                                className="form-control"
                                {...register(`self_checkout_standby_audios.${index}.start_time`, { required: true })}
                                type="time"
                              />
                            </div>

                            <div className="col">
                              <label className="form-label">終了時間</label>
                              <input
                                className="form-control"
                                {...register(`self_checkout_standby_audios.${index}.end_time`, { required: true })}
                                type="time"
                              />
                            </div>

                            <div className="col">
                              <label className="form-label">音声URL</label>
                              <input
                                className="form-control"
                                {...register(`self_checkout_standby_audios.${index}.audio_url`, {
                                  required: true,
                                })}
                                type="text"
                              />
                            </div>

                            <div className="col">
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => removeSelfCheckoutStandbyAudios(index)}
                              >
                                削除
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <hr />

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">タイムセール</label>

                    {shop.kitchen_shop_id ? (
                      <div className="col-sm-8">
                        <p className="text-warning">親店舗のみ</p>
                      </div>
                    ) : (
                      <div className="col-sm-12">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() =>
                            append({
                              dates: [],
                              start_time: '',
                              end_time: '',
                              sales_channels: [],
                              discount_amount: 100,
                              order_app: false,
                              self_checkout: true,
                              qr_bento: false,
                              self_checkout_audio_url: '',
                            })
                          }
                        >
                          追加
                        </button>

                        {fields.map((field2, index) => (
                          <div key={field2.id} className="row ml-5 mb-3">
                            <div className="col">
                              <label className="form-label">日付</label>
                              <Controller
                                control={control}
                                {...register(`time_discounts.${index}.dates`, { required: true })}
                                rules={{ required: true }} // optional
                                render={({ field: { onChange, name, value } }) => (
                                  <>
                                    <DatePicker
                                      multiple
                                      plugins={[<DatePanel />]}
                                      value={value || []}
                                      onChange={(dates: DateObject[]) => {
                                        onChange(dates.map((date) => date.format('YYYY-MM-DD')));
                                      }}
                                      format="YYYY-MM-DD"
                                    />
                                  </>
                                )}
                              />
                            </div>

                            <div className="col">
                              <label className="form-label">開始時間</label>
                              <input
                                className="form-control"
                                {...register(`time_discounts.${index}.start_time`, { required: true })}
                                type="time"
                              />
                            </div>

                            <div className="col">
                              <label className="form-label">終了時間</label>
                              <input
                                className="form-control"
                                {...register(`time_discounts.${index}.end_time`, { required: true })}
                                type="time"
                              />
                            </div>

                            <div className="col">
                              <label className="form-label">販売チャネル</label>
                              <select
                                className="form-select"
                                {...register(`time_discounts.${index}.sales_channels`)}
                                multiple
                              >
                                {salesChannels.map((salesChannel) => (
                                  <option value={salesChannel.value}>{salesChannel.name}</option>
                                ))}
                              </select>
                            </div>

                            <div className="col">
                              <div>
                                <input
                                  id={`form-check-input-order-app-${index}`}
                                  className="form-check-input"
                                  type="checkbox"
                                  {...register(`time_discounts.${index}.order_app`)}
                                />
                                <label className="form-label" htmlFor={`form-check-input-order-app-${index}`}>
                                  オーダーアプリ
                                </label>
                              </div>

                              <div>
                                <input
                                  id={`form-check-input-self-checkout-${index}`}
                                  className="form-check-input"
                                  type="checkbox"
                                  {...register(`time_discounts.${index}.self_checkout`)}
                                />
                                <label className="form-label" htmlFor={`form-check-input-self-checkout-${index}`}>
                                  セルフレジ
                                </label>
                              </div>

                              <div>
                                <input
                                  id={`form-check-input-qr-bento-${index}`}
                                  className="form-check-input"
                                  type="checkbox"
                                  {...register(`time_discounts.${index}.qr_bento`)}
                                />
                                <label className="form-label" htmlFor={`form-check-input-qr-bento-${index}`}>
                                  QR弁当
                                </label>
                              </div>
                            </div>

                            <div className="col">
                              <label className="form-label">値引き額</label>
                              <input
                                className="form-control"
                                {...register(`time_discounts.${index}.discount_amount`, { required: true })}
                                type="number"
                              />
                            </div>

                            <div className="col">
                              <label className="form-label">音声URL</label>
                              <input
                                className="form-control"
                                {...register(`time_discounts.${index}.self_checkout_audio_url`, { required: true })}
                                type="text"
                              />
                            </div>

                            <div className="col">
                              <button type="button" className="btn btn-danger" onClick={() => remove(index)}>
                                削除
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <hr />

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">ラベルプリンター</label>

                    {shop.kitchen_shop_id ? (
                      <div className="col-sm-8">
                        <p className="text-warning">親店舗のみ</p>
                      </div>
                    ) : (
                      <div className="col-sm-8">
                        <select multiple size={6} {...register('label_printer_sales_channels', {})}>
                          {salesChannels.map((salesChannel) => (
                            <option key={salesChannel.value} value={salesChannel.value}>
                              {salesChannel.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">積み込みタイミング</label>

                    {shop.kitchen_shop_id ? (
                      <div className="col-sm-8">
                        <p className="text-warning">親店舗のみ</p>
                      </div>
                    ) : (
                      <div className="col-sm-8">
                        <select id="inputStatus" {...register('loading_stocks_timing', {})} onChange={statusChanged}>
                          <option value="sameday">当日</option>
                          <option value="previousday">前日</option>
                        </select>
                      </div>
                    )}
                  </div>

                  <hr />

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">盛り付けクルーレベル</label>

                    {shop.kitchen_shop_id ? (
                      <div className="col-sm-8">
                        <p className="text-warning">親店舗のみ</p>
                      </div>
                    ) : (
                      <div className="col-sm-8">
                        <input type="number" {...register('cooking_crew_level', {})} />
                        <small className="text-danger">{errors.order && '必須です'}</small>
                      </div>
                    )}
                  </div>

                  <hr />

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">盛り付けスキップ</label>
                    <div className="col-sm-8">
                      <input type="checkbox" size={20} {...register('skip_cooking_phase', {})} />
                    </div>
                  </div>

                  <hr />

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">ステータス</label>
                    <div className="col-sm-8">
                      <select id="inputStatus" {...register('status', {})} onChange={statusChanged}>
                        <option value="active">有効</option>
                        <option value="inactive">未使用/閉店</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">オーダーアプリに表示</label>
                    <div className="col-sm-8">
                      <input type="checkbox" size={20} disabled={shopDisabled} {...register('enabled', {})} />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">ホームページに表示</label>
                    <div className="col-sm-8">
                      <input type="checkbox" size={20} disabled={shopDisabled} {...register('homepage_enabled', {})} />
                      <small className="form-text text-muted">アプリに表示も選択されている必要がある</small>
                    </div>
                  </div>

                  <hr />

                  <input type="submit" className="btn btn-primary" value={isExistingShop ? '更新' : '作成'} />
                </form>
              </div>
            ) : (
              <div className="row">
                <div className="col text-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            )}
            <WindmillSpinnerOverlay loading={posting} message="店舗情報/スロット更新中" />
          </>
          {isExistingShop ? (
            <NavLink to={`/admin/shops_private/${id}`} className="nav-link" activeClassName="active">
              内部情報の更新
            </NavLink>
          ) : null}
        </div>
      ) : (
        'loading...'
      )}
    </div>
  );
}

export default Shop;
