import React, { useCallback, useEffect, useState } from 'react';

import Modal from 'react-modal';
import { WindmillSpinnerOverlay } from 'react-spinner-overlay';
import dayjs from './dayjs';
import firebase from './firebase';
import Utils from './Utils';
import { StoreContainer } from './store';
import useLocalStorage from './use_local_storage';
import addLabelPrinterJob from './addLabelPrinterJob';

Modal.setAppElement('#root');

function ToppingListItem(props: {
  elementItem: firebase.firestore.DocumentData;
  toppingPlan: any;
  closingTime: Date;
  inProgress: Function;
}) {
  const storeContainer = StoreContainer.useContainer();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newStatus, setNewStatus] = useState<string>();
  const [defaultStatusUntil, setDefaultStatusUntil] = useState<Date | null>(null);
  const [warningStock, setWarningStock] = useState(false);

  const [stockWeightFormula, setStockWeightFormula] = useState('');
  const [wasteWeightFormula, setWasteWeightFormula] = useState('');
  const [calculatedStocks, setCalculatedStocks] = useState<number | null>(null);
  const [calculatedWaste, setCalculatedWaste] = useState<number | null>(null);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const inputStockRef = React.createRef<HTMLInputElement>();
  const inputWasteRef = React.createRef<HTMLInputElement>();

  const [stockWeight, setStockWeight] = useState<number | null>(null);
  const [stockStorageCount, setStockStorageCount] = useState<number | null>(null);

  const [wasteWeight, setWasteWeight] = useState<number | null>(null);
  const [wasteStorageCount, setWasteStorageCount] = useState<number | null>(null);

  const [storageContainerIndex, setStorageContainerIndex] = useLocalStorage(
    `element-item-${props.elementItem.id}-storage-container-index`,
    0,
  );

  const [storageContainer, setStorageContainer] = useState<{
    weight: number;
    weightName: string;
    countName: string;
  } | null>(null);

  useEffect(() => {
    let index: number;
    if (props.elementItem.data().storage_containers?.[storageContainerIndex]) {
      index = storageContainerIndex;
    } else if (props.elementItem.data().storage_containers?.[0]) {
      index = 0;
    } else {
      return;
    }

    setStorageContainer({
      weight: props.elementItem.data().storage_containers[index].weight ?? 0,
      weightName: props.elementItem.data().storage_containers[index].weight_name ?? '',
      countName: props.elementItem.data().storage_containers[index].count_name ?? '',
    });

    setStockStorageCount(props.elementItem.data().storage_containers[index].default_count ?? null);
    setWasteStorageCount(props.elementItem.data().storage_containers[index].default_count ?? null);
  }, [storageContainerIndex, props.elementItem]);

  useEffect(() => {
    const today = dayjs().tz('Asia/Tokyo').format('YYYYMMDD');

    firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .collection('kitchen_warnings')
      .where('date', '==', today)
      .where('type', '==', 'stock')
      .where('element_item_id', '==', props.elementItem.id)
      .get()
      .then((query) => {
        if (query.size > 0) {
          setWarningStock(query.docs[0].data().enabled);
        } else {
          setWarningStock(false);
        }
      });
  }, [storeContainer.shopId, props.elementItem.id]);

  const oneDecimal = (i: number) => {
    return Number.parseFloat(i.toFixed(1));
  };

  const addStock = async () => {
    const ref = firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .collection('topping_plans')
      .doc(props.elementItem.id);

    const plusMinus = Number.parseFloat(inputStockRef.current!.value);
    if (plusMinus) {
      await ref.set(
        {
          element_item_id: props.elementItem.id,
          limit_per_day: oneDecimal((props.toppingPlan?.limit_per_day ?? 0) + plusMinus),
          updating: true,
        },
        { merge: true },
      );
    }
  };

  const addWaste = async () => {
    const ref = firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .collection('topping_plans')
      .doc(props.elementItem.id);

    const plusMinus = Number.parseFloat(inputWasteRef.current!.value);
    if (plusMinus) {
      const newPlannedWaste = oneDecimal((props.toppingPlan?.planned_waste ?? 0) + plusMinus);

      await ref.set(
        {
          element_item_id: props.elementItem.id,
          planned_waste: newPlannedWaste,
          reported_planned_waste: newPlannedWaste,
          reported_planned_waste_at: firebase.firestore.Timestamp.now(),
        },
        { merge: true },
      );
    }
  };

  const switchToppingStatus = async (status, until) => {
    // TODO:  setUpdating(true);

    const query = await firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .collection('topping_plans')
      .doc(props.elementItem.id)
      .get();

    let plan;
    if (query.exists) {
      plan = query.ref;
    } else {
      plan = firebase
        .firestore()
        .collection('shops')
        .doc(storeContainer.shopId)
        .collection('topping_plans')
        .doc(props.elementItem.id);
    }

    await plan.set(
      {
        element_item_id: props.elementItem.id,
        status,
        status_until: until || null,
        status_comment: 'ストアアプリより手動更新',
        backup_status: null,
        backup_status_until: null,
      },
      { merge: true },
    );

    // TODO:    setUpdating(false);
  };

  const openModal = (newNewStatus: string, aDefaultStatusUntil: Date | null) => {
    setNewStatus(newNewStatus);
    setDefaultStatusUntil(aDefaultStatusUntil);
    setModalIsOpen(true);
  };

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    //    subtitle.style.color = '#f00';
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const toggleConfirmedSoldout = (confirmed: boolean) => {
    firebase
      .firestore()
      .collection('shops')
      .doc(storeContainer.shopId)
      .collection('topping_plans')
      .doc(props.elementItem.id)
      .update({
        crew_confirmed_soldout: confirmed,
        updating: true,
      });
  };

  const handleSubmitModal = async (e) => {
    e.preventDefault();
    const statusUntil = e.target.status_until ? dayjs.tz(e.target.status_until.value, 'Asia/Tokyo').toDate() : null;
    await switchToppingStatus(newStatus, statusUntil);
    closeModal();
  };

  const calcAmount = useCallback(
    (totalWeight: number, containerCount: number, spare: number) => {
      const storageContainerWeight = storageContainer?.weight ?? 0;

      const netWeight = totalWeight - storageContainerWeight * containerCount;

      if (netWeight <= 0) {
        return {
          count: null,
          formula: `容器の重さ ${storageContainerWeight}g x ${containerCount} よりも重い値を設定してください。`,
        };
      }

      const count = Math.floor(netWeight / props.elementItem.data().weight) - spare;

      const formula = `(${totalWeight}g - ${storageContainerWeight}g * ${containerCount}) / ${
        props.elementItem.data().weight
      }g ${spare > 0 ? `- ${spare} ` : ''}= ${count}個`;

      return { count, formula };
    },
    [props.elementItem, storageContainer],
  );

  const stocks = oneDecimal(
    props.toppingPlan.limit_per_day - (props.toppingPlan.consumed ?? 0) + (props.toppingPlan.consumed_fromnow ?? 0),
  );

  useEffect(() => {
    if (Utils.isNumber(stockWeight) && stockWeight !== null) {
      const { count, formula } = calcAmount(stockWeight, stockStorageCount || 0, 2);

      setStockWeightFormula(formula);
      setCalculatedStocks(count);
      console.log('w');
      if (Utils.isNumber(count)) {
        inputStockRef.current!.value = (count! - stocks).toString();
      } else {
        inputStockRef.current!.value = '';
      }
    } else {
      setStockWeightFormula('');
    }
  }, [stockWeight, stockStorageCount, calcAmount, inputStockRef, stocks]);

  useEffect(() => {
    if (Utils.isNumber(wasteWeight) && wasteWeight !== null) {
      const { count, formula } = calcAmount(wasteWeight, wasteStorageCount || 0, 0);

      setWasteWeightFormula(formula);
      setCalculatedWaste(count);
      if (Utils.isNumber(count)) {
        inputWasteRef.current!.value = (count! - (props.toppingPlan.planned_waste ?? 0)).toString();
      } else {
        inputWasteRef.current!.value = '';
      }
    } else {
      setWasteWeightFormula('');
    }
  }, [wasteWeight, wasteStorageCount, calcAmount, inputWasteRef, props.toppingPlan.planned_waste]);

  const handlePrint = async (e) => {
    props.inProgress(true);
    await addLabelPrinterJob({
      type: 'expiration',
      shopId: storeContainer.shopId,
      elementItemId: props.elementItem.id,
    });
    props.inProgress(false);
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div>
          {props.elementItem.data().kitchen_name}
          <form id="status_change_form" onSubmit={handleSubmitModal}>
            {newStatus !== 'available' ? (
              <>
                <input
                  type="datetime-local"
                  defaultValue={dayjs(defaultStatusUntil || props.closingTime)
                    .tz('Asia/Tokyo')
                    .format('YYYY-MM-DDTHH:mm')}
                  name="status_until"
                />{' '}
                まで
                {Utils.toppingStatusName(newStatus)}
                <br />
              </>
            ) : (
              ''
            )}
            <input
              type="submit"
              className={`btn btn-sm ${newStatus === 'soldout' ? 'btn-danger' : 'btn-warning'}`}
              value={`${Utils.toppingStatusName(
                props.toppingPlan?.status ?? 'available',
              )} から ${Utils.toppingStatusName(newStatus)} に変更`}
            />
            <button type="button" className="btn btn-sm btn-secondary" onClick={closeModal}>
              キャンセル
            </button>
          </form>
        </div>
      </Modal>

      <tr>
        <th>
          <div>{props.elementItem.data().kitchen_name}</div>
          {props.elementItem.data().kitchen_disher_name && (
            <div>
              <small className="text-muted">
                <small>提供量:</small>
                {props.elementItem.data().weight ?? '-'}
                <small>g</small>
              </small>
              <br />
              <small className="text-muted">{props.elementItem.data().kitchen_disher_name}</small>
            </div>
          )}
          {props.elementItem.data().storage_containers?.length > 0 ? (
            <div className="mt-2">
              容器
              {props.elementItem.data().storage_containers.map((s, i) => (
                <div key={`${props.elementItem.id}-${s.weight_name}`}>
                  <small className="text-muted ml-4">
                    <input
                      type="radio"
                      className="form-check-input"
                      defaultChecked={i === storageContainerIndex}
                      value={i}
                      id={`${props.elementItem.id}-${i}`}
                      name={`${props.elementItem.id}-storage-container`}
                      onChange={(e) => {
                        setStorageContainerIndex(Number.parseInt(e.target.value, 10));
                      }}
                    />
                    <label className="form-check-label" htmlFor={`${props.elementItem.id}-${i}`}>
                      {s.weight_name}
                    </label>
                  </small>
                </div>
              ))}
            </div>
          ) : null}
        </th>
        <td className="pt-0 pb-0">
          <div className="row justify-content-center align-items-center">
            <div className="col mt-2 mb-2">
              <div className="row">
                <div
                  className={`col-6 ${
                    props.toppingPlan?.crew_confirmed_soldout ? 'table-dark' : warningStock ? 'table-danger' : ''
                  }`}
                >
                  <h5>
                    <small>在庫数:</small>
                    <strong>
                      <big className={`${warningStock && 'text-danger'}`}>
                        {props.toppingPlan ? stocks : '設定なし'}
                      </big>
                    </strong>
                    &nbsp;
                    {props.toppingPlan && (
                      <small className={`!${props.toppingPlan?.crew_confirmed_soldout && 'text-muted'}`}>
                        (内本日注文済:
                        {(props.toppingPlan?.consumed_fromnow ?? 0) - (props.toppingPlan?.consumed_tomorrow ?? 0)}
                        {(props.toppingPlan?.consumed_tomorrow ?? 0) > 0 &&
                          ` 明日以降注文済 ${props.toppingPlan?.consumed_tomorrow}`}
                        &nbsp; 注文可能在庫数:{' '}
                        {props.toppingPlan.limit_per_day -
                          (props.toppingPlan.consumed ?? 0 - (props.toppingPlan?.consumed_tomorrow ?? 0))}
                        )
                      </small>
                    )}
                  </h5>
                  <div className="row">
                    <div className="col pr-0">
                      <input
                        className={`form-control ${
                          props.toppingPlan?.crew_confirmed_soldout
                            ? 'bg-dark'
                            : warningStock
                            ? 'bg-danger text-light'
                            : ''
                        }`}
                        type="number"
                        step="1"
                        ref={inputStockRef}
                        disabled={props.toppingPlan?.crew_confirmed_soldout}
                      />
                    </div>
                    <div className="col-auto pl-0">
                      &nbsp;
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={addStock}
                        disabled={props.toppingPlan?.crew_confirmed_soldout}
                      >
                        増減
                      </button>
                      &nbsp;
                      {props.toppingPlan?.crew_confirmed_soldout ? (
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => toggleConfirmedSoldout(false)}
                        >
                          終了確認済取消
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => toggleConfirmedSoldout(true)}
                        >
                          終了確認済
                        </button>
                      )}
                    </div>
                  </div>

                  {warningStock && (
                    <small className="text-danger">
                      在庫数を確認して増減してください。またこの在庫数が正しい場合には「終了確認済」を押してください。
                    </small>
                  )}

                  {props.elementItem.data().weight && (
                    <>
                      <div className="row">
                        <div className="col-7 pr-0">
                          <input
                            className={`form-control ${
                              props.toppingPlan?.crew_confirmed_soldout
                                ? 'bg-dark'
                                : warningStock
                                ? 'bg-danger text-light'
                                : ''
                            }`}
                            type="number"
                            onChange={(e) => {
                              setStockWeight(Number.parseInt(e.target.value, 10));
                            }}
                            placeholder={storageContainer?.weightName ?? ''}
                            disabled={props.toppingPlan?.crew_confirmed_soldout}
                          />
                        </div>
                        {storageContainer && (
                          <div className="col-5 pl-1">
                            <input
                              className={`form-control ${
                                props.toppingPlan?.crew_confirmed_soldout
                                  ? 'bg-dark'
                                  : warningStock
                                  ? 'bg-danger text-light'
                                  : ''
                              }`}
                              type="number"
                              onChange={(e) => {
                                setStockStorageCount(Number.parseInt(e.target.value, 10));
                              }}
                              defaultValue={stockStorageCount ?? ''}
                              placeholder={storageContainer.countName}
                              disabled={props.toppingPlan?.crew_confirmed_soldout}
                            />
                          </div>
                        )}
                      </div>
                      <div className="row">
                        <div className="col">
                          <small className="form-text text-muted">{stockWeightFormula}</small>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          {calculatedStocks ? (
                            <>
                              現在の在庫数との差: {calculatedStocks - stocks}
                              <span>✅</span>
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="col-6">
                  <h5>
                    <small>廃棄(予定)数:</small>
                    <strong>
                      <big>{props.toppingPlan ? props.toppingPlan.planned_waste : '設定なし'}</big>
                    </strong>
                    {props.toppingPlan.reported_planned_waste_at && (
                      <small className="ml-2">
                        前回入力:
                        <strong className="ml-1">
                          {props.toppingPlan.reported_planned_waste}
                          <span className="ml-1">
                            (
                            {dayjs(props.toppingPlan.reported_planned_waste_at.toDate())
                              .tz('Asia/Tokyo')
                              .format('HH:mm')}
                            )
                          </span>
                        </strong>
                      </small>
                    )}
                  </h5>

                  <div className="row">
                    <div className="col pr-0">
                      <input className="form-control" type="number" step="1" ref={inputWasteRef} />
                    </div>
                    <div className="col-auto pl-0">
                      &nbsp;
                      <button type="button" className="btn btn-primary" onClick={addWaste}>
                        増減
                      </button>
                      &nbsp;
                    </div>
                  </div>
                  {props.elementItem.data().weight && (
                    <>
                      <div className="row">
                        <div className="col-7 pr-0">
                          <input
                            className="form-control"
                            type="number"
                            onChange={(e) => {
                              setWasteWeight(Number.parseInt(e.target.value, 10));
                            }}
                            placeholder={storageContainer?.weightName ?? ''}
                          />
                        </div>
                        {storageContainer && (
                          <div className="col-5 pl-1">
                            <input
                              className="form-control"
                              type="number"
                              onChange={(e) => {
                                setWasteStorageCount(Number.parseInt(e.target.value, 10));
                              }}
                              defaultValue={wasteStorageCount ?? ''}
                              placeholder={storageContainer.countName}
                            />
                          </div>
                        )}
                      </div>
                      <div className="row">
                        <div className="col">
                          <small className="form-text text-muted">{wasteWeightFormula}</small>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          {calculatedWaste ? (
                            <>
                              現在の廃棄(予定)数との差
                              <button type="button" className="btn btn-sm btn-light">
                                {calculatedWaste - (props.toppingPlan.planned_waste ?? 0)}
                              </button>
                              <span>✅</span>
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-auto align-middle text-right">
              {!props.toppingPlan || !props.toppingPlan.status || props.toppingPlan.status === 'available' ? (
                <div>
                  <button type="button" className="btn btn-sm btn-danger" onClick={() => openModal('soldout', null)}>
                    {Utils.toppingStatusName('soldout')}
                  </button>
                  &nbsp;
                  <button type="button" className="btn btn-sm btn-warning" onClick={() => openModal('free', null)}>
                    {Utils.toppingStatusName('free')}
                  </button>
                </div>
              ) : (
                <div>
                  {`${Utils.toppingStatusName(props.toppingPlan.status)}中`}
                  <br />
                  {props.toppingPlan.status_until ? (
                    <span>
                      {dayjs(props.toppingPlan.status_until.toDate()).tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm')}
                      まで
                    </span>
                  ) : (
                    ''
                  )}
                  <br />
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={() =>
                      openModal(
                        props.toppingPlan.backup_status || 'available',
                        props.toppingPlan.backup_status_until ? props.toppingPlan.backup_status_until.toDate() : null,
                      )
                    }
                  >
                    {`${Utils.toppingStatusName(props.toppingPlan.status)}終了`}
                  </button>
                </div>
              )}
              {props.elementItem.data().weekly_expiration_days[dayjs().tz('Asia/Tokyo').weekday()] !== null && (
                <>
                  <br />
                  <button type="button" className="btn btn-sm btn-dark" onClick={handlePrint}>
                    期限印刷
                  </button>
                </>
              )}
            </div>
          </div>
          <WindmillSpinnerOverlay loading={!!props.toppingPlan?.updating} />
        </td>
      </tr>
    </>
  );
}

export default ToppingListItem;
