// eslint-disable-next-line max-classes-per-file
import React, { useContext, useEffect, useState } from 'react';
import ReactDataSheet from 'react-datasheet';
// Be sure to include styles at some point, probably during your bootstrapping
import 'react-datasheet/lib/react-datasheet.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ShopsContext from './ShopsContext';
import firebase from './firebase';
import Utils from './Utils';
import useSalesShops from './hooks/useSalesShops';
import useSalesPresets from './hooks/useSalesPresets';

export interface GridElement extends ReactDataSheet.Cell<GridElement, number> {
  value: number | null;
}

class MyReactDataSheet extends ReactDataSheet<GridElement, number> {}

interface AppState {
  grid: GridElement[][];
}

class QrBentoHandler {
  shopId: string;

  salesPresetId: string;

  constructor(shopId: string, salesPresetId: string) {
    this.shopId = shopId;
    this.salesPresetId = salesPresetId;
  }

  update(value: number, batch: firebase.firestore.WriteBatch) {
    const ref = firebase.firestore().collection('qr_bento_plans').doc(`${this.shopId}-${this.salesPresetId}`);

    batch.set(
      ref,
      {
        shop_id: this.shopId,
        sales_preset_id: this.salesPresetId,
        max_quantity: value,
      },
      { merge: true },
    );

    return true;
  }
}

class ShopQrBento {
  shopId: string;

  maxQuantities = {};

  name: string;

  order: number;

  constructor(shopId: string, name: string, order: number) {
    this.shopId = shopId;
    this.name = name;
    this.order = order;
  }

  updateValue(salesPresetId: string, value: number) {
    this.maxQuantities[salesPresetId] = value;
  }
}

function transpose(a, defaultValue) {
  return Object.keys(a[0]).map((c) => {
    return a.map((r) => {
      return r[c] || defaultValue;
    });
  });
}

function ShopQrBentoPlanUpdate() {
  const { shops } = useContext(ShopsContext);
  const [shopQrBentos, setShopQrBentos] = useState<{ [key: string]: ShopQrBento }>();
  const salesPresets = useSalesPresets();
  const salesShops = useSalesShops();

  const [data, setData] = useState<Array<any>>([]);

  useEffect(() => {
    firebase
      .firestore()
      .collection('qr_bento_plans')
      .get()
      .then((snapshot) => {
        const newShopQrBentos: { [key: string]: ShopQrBento } = {};

        for (const shop of Object.keys(shops)
          .map((shopId) => shops[shopId])
          .filter((s) => !s.data()!.kitchen_shop_id && s.data()!.status === 'active')) {
          newShopQrBentos[shop.id] = new ShopQrBento(shop.id, shop.data()!.short_name, shop.data()!.order);
        }

        for (const doc of snapshot.docs) {
          const plan = doc.data()!;
          const shopId = plan.shop_id;

          if (!newShopQrBentos[shopId]) {
            continue;
          }

          const shopRecipe = newShopQrBentos[shopId];

          if (plan.sales_preset_id) {
            shopRecipe.updateValue(plan.sales_preset_id, plan.max_quantity);
          }
        }

        setShopQrBentos(newShopQrBentos);
      });
  }, [shops]);

  useEffect(() => {
    if (shopQrBentos && salesPresets && salesShops) {
      const grid: any = [];

      const presetNames = salesPresets.map((preset) => {
        return {
          value: preset.data()!.name,
          width: 200,
          readOnly: true,
        };
      });
      const header = [{ value: '', width: 200, readOnly: true }].concat(presetNames);

      grid.push(header);

      for (const shopRecipe of Object.values(shopQrBentos).sort((a, b) => a.order - b.order)) {
        const salesPresetRow = salesPresets.map((salesPreset) => {
          const foundSalesShop = salesShops.find(
            (salesShop) =>
              salesShop.data()!.shop_id === shopRecipe.shopId &&
              salesShop.data()!.qr_bento_sales_preset_ids.includes(salesPreset.id),
          );

          return {
            value: shopRecipe.maxQuantities[salesPreset.id],
            width: 150,
            readOnly: !foundSalesShop,
            handler: new QrBentoHandler(shopRecipe.shopId, salesPreset.id),
          };
        });

        const row = [{ value: shopRecipe.name, width: 150, readOnly: true }].concat(salesPresetRow);
        grid.push(row);
      }
      setData(transpose(grid, { value: '', width: 150 }));
    }
  }, [shopQrBentos, salesPresets, salesShops]);

  const onGridRowsUpdated = (changes) => {
    setData((prevData) => {
      const batch = firebase.firestore().batch();
      const grid = prevData.map((row) => [...row]);
      changes.forEach(({ cell, row, col, value }) => {
        let updatingValue: null | number = null;
        let valid = false;
        if (value === '') {
          updatingValue = null;
          valid = true;
        } else {
          const intValue = parseInt(value, 10);
          if (Utils.isNumber(intValue)) {
            updatingValue = intValue;
            valid = true;
          }
        }

        if (valid) {
          grid[row][col] = { ...grid[row][col], value: updatingValue };

          if (cell.handler) {
            cell.handler.update(updatingValue, batch);
          }
        }
      });

      batch.commit();
      return grid;
    });
  };

  return (
    <>
      {salesPresets && shopQrBentos && (
        <MyReactDataSheet
          className="reset-bootstrap-for-datasheet"
          data={data}
          valueRenderer={(cell) => cell.value}
          onCellsChanged={onGridRowsUpdated}
        />
      )}
    </>
  );
}

export default ShopQrBentoPlanUpdate;
